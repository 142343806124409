import React from 'react';
import { Link } from 'gatsby';

//Components
import Layout from '../components/layout';

const NotFoundPage = ({ pageContext }) => (
  <Layout lang={pageContext.langKey}>
    <section className="pt-27 pb-40">
      <div className="container">
        <p className="text-title mb-4">Грешка 404</p>

        <h1 className="text-hero mb-5">Страницата не е намерена</h1>

        <Link
          className="btn btn-primary rounded-lg"
          to={`/${pageContext.langKey}`}
        >
          <i className="far fa-long-arrow-left mr-4"></i>
          Обратно към основната страница
        </Link>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
